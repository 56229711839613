.StoreFarmingPage {
  .sf-custom-tab {
    .ant-tabs-nav {
      margin-bottom: 0;
      .ant-tabs-tab {
        padding: 12px 0px;
      }
    }
  }
  .sf-range-picker {
    height: 32px;
    border-radius: 4px;
    align-self: center;
    margin-right: var(--sp_lv_6);
    input {
      text-align: center;
    }
  }

  .jobs-progress-container {
    display: grid;
    grid-template: 50% 50% / 25% 25% 25% 25%;
    .custom-metric-card {
      border: unset;
    }
    .metric-card {
      // border-top: unset;
      height: 106px;
      border: unset;
    }
    .grid-box-1 {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
    }
    .TasksReportComponent {
      grid-column: 1 / 3;
      grid-row: 2 / 3;
      width: 100%;
      height: unset;
      padding-left: var(--sp_lv_6);
    }
  }

  .chart-container {
    height: 367px;
  }
}

.LeaderBoardsComponent {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--sp_lv_4);
}

@media (max-width: 1445) {
  .LeaderBoardsComponent {
    justify-content: flex-start;
  }
}

.break {
  flex-basis: 100%;
  height: 0;
}
